// Define system wide settings for the headers, lists, and other html types
// Todo: MG, should this be called types? Is there a better name?

//
// Headings
//


//
//
//
.d-flex{
  display: flex;
}
.d-flex-block{
  display: block;
}
.d-flex-inline-block{
  display: inline-block;
}

//
// Lists
//
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

//
// Add pointer to cursor to highlight links
//
.cursor-pointer{
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

//
// Common bootstrap-like behaviours
//
.w-100 {
  width: 100%;
}
