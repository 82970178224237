@import "styles/krucial_styles";
@import "styles/types";
@import "styles/krucial_table";
@import "styles/fxlayout";
@import "@r3-iot/common/styles/kfx-hide";
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// Media Queries
$phone: 30rem;
$tablet: 48rem;
$laptop: 64rem;

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 1.875rem #424242 inset;
}
/*Change text in autofill textbox*/
input:-webkit-autofill
{
  -webkit-text-fill-color: white;
}
