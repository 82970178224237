
// replacement style for fxLayout

.column-layout-align-center {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
}

.row-layout-align-center {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
}

.row-layout-align-end {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-end;
  align-items: stretch;
}

.row-space-between-center {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: space-between;
  align-items: center;

}
.row-end-center {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-end;
  align-items: center;

}
