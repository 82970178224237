// https://material.angular.io/guide/theming
// https://www.positronx.io/create-angular-material-custom-theme/
// https://www.digitalocean.com/community/tutorials/angular-angular-material-custom-theme
// http://mcg.mbitson.com (Generate palettes)
@use '@angular/material' as mat;
@import '@angular/material';
@include mat.core();

// Define custom colors. We must set these manually, as Material Design uses standardised colours for backgrounds.
$background-color: #1d252c;
$primary-color: #10cfc9;

// Variables for palette
// This doesnt feel right, but I havent found a way to get the color from the theme, so it'll do for now
body {
  --background-color: $background-color;
  --primary-color: #f3556c;
  --text-primary-color: #ffffff;
}

$font-config: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);

$dark-primary-palette: (
  50 : #feebed,
  100 : #fbccd3,
  200 : #f9aab6,
  300 : #f78898,
  400 : #f56f82,
  500 : #f3556c,
  600 : #f14e64,
  700 : #ef4459,
  800 : #ed3b4f,
  900 : #ea2a3d,
  A100 : #ffffff,
  A200 : #fff6f6,
  A400 : #ffc3c8,
  A700 : #ffa9b0,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$dark-accent-palette: (
  50 : #eeeeef,
  100 : #d4d5d7,
  200 : #b7b9bc,
  300 : #9a9da1,
  400 : #84888d,
  500 : #6e7379,
  600 : #666b71,
  700 : #5b6066,
  800 : #51565c,
  900 : #3f4349,
  A100 : #aacafa,
  A200 : #79acf8,
  A400 : #3f8cff,
  A700 : #257cff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$dark-warn-palette: (
  50 : #fee5e7,
  100 : #fbbec2,
  200 : #f9939a,
  300 : #f76872,
  400 : #f54753,
  500 : #f32735,
  600 : #f12330,
  700 : #ef1d28,
  800 : #ed1722,
  900 : #ea0e16,
  A100 : #ffffff,
  A200 : #ffe2e3,
  A400 : #ffafb1,
  A700 : #ff9698,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$dark-theme-primary: mat.define-palette($dark-primary-palette, 500);
$dark-theme-accent:  mat.define-palette($dark-accent-palette, 500, 900, A100);
$dark-theme-warn:    mat.define-palette($dark-warn-palette, 500);

$dark-theme: mat.define-dark-theme((
  color: (
    primary: $dark-theme-primary,
    accent: $dark-theme-accent,
    warn: $dark-theme-warn
  ),
  density: 0,
  typography: $font-config
));

$light-theme: mat.define-light-theme((
  color: (
    primary: $dark-theme-primary,
    accent: $dark-theme-accent,
    warn: $dark-theme-warn
  )
));

$theme-color: map-get($dark-theme, color);
$background: map-get($theme-color, ( background ));
$background: map_merge($background, (background: $background-color));
$background: map_merge($background, (card: lighten($background-color, 5%)));
$background: map_merge($background, (dialog: lighten($background-color, 5%)));
$background: map_merge($background, (input: $background-color));

$theme-color: map_merge($theme-color, (background: $background));
$dark-theme: map_merge($dark-theme, (color: $theme-color));

// Apply the dark theme by default
@include mat.core-theme($dark-theme);
@include mat.all-component-themes($dark-theme);
@include mat.all-component-typographies($dark-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  @include mat.all-component-colors($dark-theme);
}