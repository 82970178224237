.krucial-table-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-width: 18.75rem;

  .mat-mdc-table {
    overflow: auto;
    max-height: 100%;

    .no-padding {
      min-width: 2rem;
      padding: 0;
    }

    @media(max-width: 48rem) {
      margin-bottom: 5rem;
    }
  }

  .mat-mdc-row {
    border-bottom: 0.063rem solid transparent;
    border-top: 0.063rem solid transparent;

    .mat-mdc-row:hover {
      border-color: currentColor;
    }
  }

  .mat-mdc-row:hover {
    border-color: currentColor;
  }

  .mobile-label {
    display: none;
  }

  @media(max-width: 48rem) {
    .mat-mdc-paginator {
      position: fixed;
      bottom: 0;
      left: 0;
      //TODO background-color: $dark-bg-lighter-5;
      justify-content: center;
      //TODO border: solid $light-dividers;
      border-width: 1px 0;
    }

    .mat-mdc-cell {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      > span {
        flex: 50;
        min-width: 50%;
        max-width: 50%;
      }

      button {
        padding: 0;
      }
    }

    .mobile-label {
      display: inline-block;
      font-weight: bold;
    }

    .mat-mdc-header-row {
      display: none;
    }

    .mat-mdc-row {
      flex-direction: column;
      align-items: start;
      padding: 0.5rem 1.5rem;
      border-bottom-width: 1px;
      border-top-width: 0;
      border-color: #ffffff1f;
    }

    .mdc-data-table__cell {
      border: 0 !important;
      padding-left: 16px !important;
    }
  }

  .actions-footer{
    margin-left: 80%;
    padding: 0.625rem;

    @media(max-width: 48rem) {
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }

  .clickable {
    cursor: pointer;
  }
}
